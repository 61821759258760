<template>
  <footer class="footer">
    <div class="content has-text-centered">
      <p>
        &#169; Pesto Group, Inc. All Rights Reserved
      </p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@import '@/styles/app.scss';
</style>
