<template>
  <div id="Release" class="container is-fluid">
    <img alt="Pesto PointMan" src="@/assets/logo.png" class="pesto-logo" />
    <h1><strong>v0.11.3</strong> - 6.18.2024</h1>
    <div class="columns">
      <div class="column">
        <a class="button is-success is-large is-home"
        href="https://pointman.pestosoftworks.com/Pointman_0.11.3_universal.dmg">
          Download<br>
          for Mac
        </a>
      </div>
      <div class="column">
        <a class="button is-success is-large is-home"
        href="https://pointman.pestosoftworks.com/Pointman_0.11.3_x64_en-US.msi">
          Download<br>
          for Windows
        </a>
      </div>
    </div>
    <p>
      Looking for a <a class="archive-link" href="https://pointman.pestosoftworks.com/archive/">prior version?</a>
    </p>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/app.scss';

a {
  &.archive-link {
    text-decoration: underline;
  }
}

.button {
  padding-top: 40px;
  padding-bottom: 40px;
}

.columns {
  margin-top: 15px;
  max-width: 480px;
  margin-left: auto;
  margin-right: auto;
}

.pesto-logo {
  margin-top: 30px;
}

h1 {
  font-size: 24px;
  margin-top: 15px;
}
</style>
