<template>
  <div id="ViewHeader">
    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <a class="navbar-item" href="https://www.thepestogroup.com">
          <img src="@/assets/the-pesto-group-logo.png" alt="The Pesto Group" />
        </a>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>

      <div id="ViewHeader" class="navbar-menu">
        <div class="navbar-start">
          <!-- <router-link :to="{ name: 'Home' }" class="navbar-item">
            Home
          </router-link> -->
        </div>

        <div class="navbar-end">
          <div class="navbar-item">
            <div class="buttons">
              <!--  -->
            </div>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<style lang="scss" scoped>
@import '@/styles/app.scss';

#ViewHeader {
  margin: 0;
  padding: 0;

  .navbar-brand {
    padding-top: 7px;
  }

  .navbar {
    .navbar-item {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 5px;
      padding-right: 5px;

      color: white;
      border-bottom: 3px $pesto-dark-gray solid;
    }
  }
}
</style>
