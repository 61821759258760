
import { defineComponent } from 'vue';
import Release from '@/views/Release.vue';
import ViewHeader from '@/components/ViewHeader.vue';
import ViewFooter from '@/components/ViewFooter.vue';

export default defineComponent({
  name: 'App',
  components: {
    Release,
    ViewFooter,
    ViewHeader,
  },
});
